import React from "react"
import FrontmatterPageContext from "~context/FakePageContext"

const Frontmatter = (props) => {
  const page = React.useContext(FrontmatterPageContext);

  const { k } = props

  return (
    <>{page?.[k] ?? ""}</>
  )
}

export default Frontmatter
