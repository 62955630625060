import React from "react"
import siteLangData from "~data/languages.yml"
import FrontmatterPageContext from "~context/FakePageContext"

import { ConsistentlyProvider, LanguageSelector as LanguageSelectorRoot } from "@fastly/consistently-vanilla"

const LanguageDropdown = (props) => {
  const page = React.useContext(FrontmatterPageContext)

  if (typeof page.i18n === "undefined" || page.i18n === null || page.i18n.length === 0) {
    return null
  }

  const { pathname } = props.location
  const options = []

  // loop over all the system languages
  for (const [langCode, langMeta] of Object.entries(siteLangData)) {
    let langUrl = pathname

    //   for each one, if either the current page is that language, or it is in the page.frontmatter.i18n array, create a menu option for it

    if (langCode == page.lang) {
      options.push({
        id: langMeta.id,
        name: langMeta.lname,
        languageCode: langMeta.iso,
        href: langUrl,
      })
      continue
    }

    //   in the case of the current page, use the current page's pathname (via the props.location), other wise calculate it
    if (page?.i18n.includes(langCode)) {
      // the loop iteration is IN the page's "link to other languages explicit list", so make a link!

      //     in the case of calculating it, first check page.frontmatter.i18n_path for the same language key, use it first;
      if (page?.i18n_path?.[langCode]) {
        langUrl = page.i18n_path[langCode]
      } else {
        //     other wise calculate the url based on the page.frontmatter.section
        switch (page?.section) {
          case "guides":
            langUrl = `/${langMeta.iso}/guides/${page.fileslug}`
            break
          case "ngwaf":
            langUrl = `/${langMeta.iso}/ngwaf/${page.fileslug}`
            break
          case "account":
            langUrl = `/${langMeta.iso}/guides/${page.fileslug}`
            break
          case "compute":
            langUrl = `/${langMeta.iso}/guides/${page.fileslug}`
            break
          case "full_site_delivery":
            langUrl = `/${langMeta.iso}/guides/${page.fileslug}`
            break
          case "getting_started":
            langUrl = `/${langMeta.iso}/guides/${page.fileslug}`
            break
          case "integrations":
            langUrl = `/${langMeta.iso}/guides/${page.fileslug}`
            break
          case "security":
            langUrl = `/${langMeta.iso}/guides/${page.fileslug}`
            break
          case "products":
            langUrl = `/products/${page.fileslug}`
            break
          default:
            // note, this should never get hit
            console.error(
              "hit the default case on page.section in langDropdown for lang[%s] and section[%s]",
              langCode,
              page.section
            )
        }
      }

      options.push({
        id: langMeta.id,
        name: langMeta.lname,
        languageCode: langMeta.iso,
        href: langUrl,
      })
    }
  }

  const handleSelect = (clickedOption) => {
    window.location = clickedOption.href
  }

  return (
    <ConsistentlyProvider reset>
      <LanguageSelectorRoot
        defaultSelected={page.lang}
        id={"language-selector"}
        locales={options}
        onSelect={handleSelect}
      />
    </ConsistentlyProvider>
  )
}

export default LanguageDropdown
