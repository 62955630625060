import React from "react"

import LayoutCore from "~templates/layout-core"
import { mainArea } from "~vanilla/layout-default.css"
import { main, heading, entranceContent, entranceContentIconPlaceholder } from "~vanilla/layout-section_index.css"
import { sideMenu } from "~components/SideMenu/side-menu.css"

import { H1, SideMenu } from "@fastly/consistently"

import FrontmatterPageContext from "~context/FakePageContext"
import LanguageDropdown from "~components/lang-dropdown"
import { BreadcrumbsPrimary } from "~components/Breadcrumbs/Breadcrumbs"
import { SideMenuSwitcher } from "~components/SideMenu/side-menu"

const LayoutSectionIndex = (props) => {
  const frontmatter = React.useContext(FrontmatterPageContext)
  const { children, location, title_content } = props

  return (
    <>
      <FrontmatterPageContext.Provider value={frontmatter}>
        <LayoutCore>
          <div className={`${mainArea} grid-main`}>
            <SideMenu
              className={`${sideMenu} main-nav section-${frontmatter.section_safe}`}
              aria-label="Navigation between documents"
              data-swiftype-index="false"
            >
              <SideMenuSwitcher />
            </SideMenu>

            <main className={`${main} main-content section-${frontmatter.section_safe}`} role="main">
              <div className="content-crumb">
                <BreadcrumbsPrimary />
              </div>

              <div className="content-lang" data-swiftype-index="false">
                <LanguageDropdown location={location} />
              </div>

              <div className={`content-title ${heading}`}>
                {title_content.icon && (
                  <div className="svg_icon">
                    <img data-todo-class='no-shadow' role="img" alt="" src={`/assets/images/icons/${title_content.icon}.svg`} width="48"/>
                  </div>
                )}
                <H1 m="0">{title_content.title}</H1>
              </div>

              <div className="content-content" data-from="section_index">
                <div className={entranceContent}>
                  {title_content.icon && <div className={entranceContentIconPlaceholder} />}
                  <p>{title_content.desc}</p>
                </div>

                <hr />

                {children}
              </div>
            </main>
          </div>
        </LayoutCore>
      </FrontmatterPageContext.Provider>
    </>
  )
}

export default LayoutSectionIndex
