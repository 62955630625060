import * as React from "react"

export const FavHead = (props) => {
  return <>
    <link rel="icon" type="image/svg+xml" sizes="any" href="/assets/fav/favicon.svg" />
    <link rel="mask-icon" href="/assets/fav/mask-icon.svg" color="#00759C" />
    <link rel="apple-touch-icon" type="image/png" sizes="180x180" href="/assets/fav/apple-touch-icon-180x180.png" />
    <link rel="manifest" href="/site.webmanifest" />
  </>
}
